import React, { useContext, useEffect } from "react";
import { KfHeroUtility } from "@klickinc/kf-react-components";
import AppContext from "@src/context"
// Utility imports
import Seo from "@components/utility/SEO";
import { Link } from "gatsby"

import "./styles.scss";

const ServerError = () => {
	const ctx = useContext(AppContext);

	useEffect(() => {
		ctx.setPageClass('page--500');
	},[]);

	return (<>
		<section className="">
			<div className="container container--inner">
				<KfHeroUtility addedClass="heading-hero-blue heading">
					<span>500</span>
					<br />
					Sorry!
				</KfHeroUtility>
				<p className="pb-12">
					It appears something has gone wrong on our end. Please try
					again later. We apologize for the inconvenience.
				</p>
				<div className="text-center sm:text-left mb-28">
					<Link to='/' className='link-btn link-btn--blue sm:max-w-[18.75rem] md:max-w-[16rem] link-hero-dark-blue'>Return Home</Link>
				</div>
			</div>
		</section>
		<div className="bg-bottom"></div>
	</>)
}

export default ServerError;

export function Head() {
    return (
		<Seo title="Server Error - EMFLAZA® (deflazacort)"/>
    );
};
